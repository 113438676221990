<template>
  <PxHeader :activeSearchMobile="false" />
  <section v-if="isBusy" class="containerGifLoading">
    <img src="@/assets/images/loaderagrogo.gif" class="sizeGifLoading" alt="" />
  </section>
  <section v-else class="bg-agrogo-green-2 sizeMinAllView">
    <!-- BOTON FLOTANTE PUBLICAR FINCA  -->
    <publish-button />
    <div
      class="bg-agrogo-yellow-1 h-12 md:h-16 flex items-center justify-start md:justify-center lg:justify-center"
    >
      <div
        class="font-nexa font-normal text-lg text-agrogo-green-2 pl-6 md:text-2xl md:pl-0"
      >
        <span class="font-nexa font-black">Mis</span> Fincas
      </div>
    </div>
    <div
      class="px-4 pt-3 pb-4 md:px-8 lg:px-16 xl:px-32 md:pt-8 lg:pt-8 md:pb-28 lg:pb-28"
    >
      <div class="lg:hidden" v-if="recordsCopy.length > 0">
        <filter-mobile
          :farmTypes="farmTypes"
          :departments="departments"
          :status="status"
          :records="recordsCopy"
          @filterFarms="filterFarms"
        />
      </div>
      <div class="hidden lg:flex">
        <div
          class="grid grid-cols-3 mt-4 gap-2 lg:mt-0 mb-6 w-full"
          v-if="recordsCopy.length > 0"
        >
          <filter-desktop
            :farmTypes="farmTypes"
            :departments="departments"
            :status="status"
            :records="recordsCopy"
            @filterFarms="filterFarms"
          />
        </div>
      </div>
      <div v-if="records.length > 0">
        <item-farm-desktop
          class="hidden md:grid"
          v-for="(item, index) in records"
          :key="index"
          :item="item"
          :cardId="cardId"
          :isBusy="isBusy2"
          :isBusy2="isBusy3"
          @saleFarm="saleFarm"
          @deleteFavorite="deleteFavorite"
        />
        <item-farm-mobile
          class="md:hidden"
          v-for="(item, index) in records"
          :key="index"
          :item="item"
          :cardId="cardId"
          :isBusy="isBusy2"
          :isBusy2="isBusy3"
          @saleFarm="saleFarm"
          @deleteFavorite="deleteFavorite"
        />
      </div>
      <div class="pt-5" v-else>
        <div class="items-center md:items-start md:px-5 md:py-6 md:mx-40">
          <div
            class="flex flex-col px-4 md:pt-3 h-auto w-auto space-y-3 md:space-y-0"
          >
            <div class="flex items-center justify-center mt-7 md:mt-12">
              <img
                class="w-14 md:w-16"
                alt=""
                src="@/assets/images/iconsuccessful.svg"
              />
            </div>
            <div class="md:flex md:justify-center py-8 pb-5 md:pb-5">
              <div class="flex items-center md:w-5/5 xl:w-3/5 px-3 md:px-0">
                <a
                  class="font-dinpro text-white text-sm text-center lg:text-2xl"
                  >No has publicado una finca, a continuación pulse el botón
                  para publicar una.</a
                >
              </div>
            </div>
            <div class="flex justify-center">
              <div
                class="w-full px-3 md:px-0 md:w-5/5 xl:w-3/5 h-0.5 bg-agrogo-yellow-1 md:mt-8"
              ></div>
            </div>
          </div>
        </div>
        <!-- CONTENEDOR BOTONES DE NAVEGACION -->
        <div class="bg-agrogo-green-2 px-4 pt-4 md:pt-2 pb-10">
          <div class="h-14 flex items-center justify-center space-x-4">
            <button
              @click="$router.push('/publicar-finca')"
              type="button"
              class="btn-primary flex items-center justify-center btn-size-step9 bg-agrogo-yellow-1 rounded-xl text-xs md:text-base text-agrogo-green-2"
            >
              Publicar finca
            </button>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { generateHash } from "@/utils";
import validationToken from "@/mixins/validationToken";
import alertMessageMixin from "@/mixins/alertMessageMixin";
import useVuelidate from "@vuelidate/core";
// Components
import PxHeader from "@/components/header/PxHeader";
import ItemFarmDesktop from "./components/ItemFarmDesktop";
import ItemFarmMobile from "./components/ItemFarmMobile";
import FilterDesktop from "./components/FilterDesktop";
import FilterMobile from "./components/FilterMobile";
import PublishButton from "@/components/publishButton/PublishButton";

export default {
  inject: ["provider"],
  mixins: [validationToken, alertMessageMixin],
  components: {
    PxHeader,
    ItemFarmDesktop,
    ItemFarmMobile,
    FilterDesktop,
    FilterMobile,
    PublishButton,
  },
  data() {
    const me = this;
    return {
      $v: useVuelidate(),
      repository: me.provider.publishFarmRepository,
      repositoryFarm: me.provider.myFarmRepository,
      isBusy: true,
      isBusy2: false,
      isBusy3: false,
      isFilter: true,
      departments: [],
      records: [],
      recordsCopy: [],
      farmTypes: [],
      status: [
        {
          id: 1,
          name: "Publicada",
        },
        {
          id: 2,
          name: "Incompleta",
        },
        {
          id: 3,
          name: " Pendiente de aprobación",
        },
        {
          id: 5,
          name: "Vendida",
        },
        {
          id: 55,
          name: "Favoritas",
        },
      ],
      imgRender: null,
      cardId: null,
    };
  },
  methods: {
    async loadDepartments() {
      const me = this;
      try {
        me.isBusy = true;
        const dataAditional = await generateHash();
        const {
          data: { data },
        } = await me.repository.getAllDeparts(dataAditional);

        me.departments = data;
      } catch (error) {
        // console.log(error);
        const code = error.response.status;
        if (code == 401) {
          me.validationExpiredToken();
        }
      } finally {
        me.isBusy = false;
      }
    },
    //DEVUELVE LA LISTA DE FINCAS
    async getListFarms() {
      const me = this;
      try {
        me.isBusy = true;
        const dataAditional = await generateHash();
        const {
          data: { data },
        } = await me.repositoryFarm.getAll(dataAditional);
        me.records = data;
        me.recordsCopy = data;
      } catch (error) {
        // console.log(error);
        const code = error.response.status;
        if (code == 401) {
          me.validationExpiredToken();
        }
      } finally {
        me.isBusy = false;
      }
    },
    async loadCategories() {
      const me = this;
      try {
        const dataAditional = await generateHash();

        const {
          data: { data },
        } = await me.repository.getAll("1", dataAditional);
        me.farmTypes = data.subcategories;
      } catch (error) {
        const code = error.response.status;
        if (code == 401) {
          me.validationExpiredToken();
        }
      }
    },
    filterFarms(records) {
      const me = this;
      me.records = records;
    },
    async saleFarm(id) {
      const me = this;
      me.cardId = id;
      try {
        me.isBusy2 = true;
        const dataAditional = await generateHash();
        await me.repositoryFarm.save({ id, ...dataAditional });
        me.records.forEach((el) => {
          if (el.id == id) {
            if (el.state.id == 5) {
              el.state.id = 1;
            } else if (el.state.id == 1) {
              el.state.id = 5;
            }
          }
        });
      } catch (error) {
        // console.log(error);
      } finally {
        me.isBusy2 = false;
      }
    },
    async deleteFavorite(id) {
      const me = this;
      me.cardId = id;
      me.isBusy3 = true;
      try {
        const dataAditional = await generateHash();
        let { data: data } = await me.repositoryFarm.delete(dataAditional, id);
        me.alertMenssage(data.response, 6000);
        const index = me.records.findIndex((el) => el.id == id);
        me.records.splice(index, 1);
        me.recordsCopy.splice(index, 1);
      } catch (error) {
        me.alertMenssage(error.response.data.response, 6000);
      } finally {
        me.isBusy3 = false;
      }
    },
  },

  async mounted() {
    const me = this;
    window.scroll(0, 0);
    await me.loadCategories();
    await me.loadDepartments();
    await me.getListFarms();
  },
};
</script>

<style lang="css" scoped></style>
